header-separator {
    border-left: solid #f5f5f5 2px;
    margin-bottom: 12px;
}

.responsive-image {
    position: relative;
    max-width: 100%;
}

.responsive-image__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
