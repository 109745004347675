#formulario-login{
    display: flex;
    justify-content: center;
    align-items: center;

}
form{
    padding-left: 20px;
    padding-bottom: 20px;
}



.card {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background: #fff;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.container {
    padding: 2px 16px;
}
