.custom-table thead > tr > th {
    background-color: white !important;
    padding-left: 0;
}

.custom-table thead > tr > th {
    text-align: right;
}

.custom-table thead > tr > th:first-child {
    text-align: left;
}

.custom-table tbody > tr > td {
    background-color: white !important;
    padding-left: 0;
}

.custom-table tbody > tr > td {
    text-align: right;
}

.custom-table tbody > tr > td:first-child {
    text-align: left;
}
